<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'LearningCardImageInfo',
  data () {
    return {
      loadingImage: true,
      preventClick: false
    }
  },
  props: {
    item: {
      type: Object,
      required
    },
    showContentLink: Function,
    seeMoreAction: Function,
    checkAction: Function,
    favoriteAction: Function,
    shareAction: Function,
    menuOptions: Array,
    addActionOptions: Array,
    menuAction: Function,
    addAction: Function,
    getTitleIcon: String,
    getTitle: String,
    titlePosition: {
      type: String,
      default: 'middle'
    },
    defaultCoverImage: String,
    coverImageHeight: Number,
    showItemsProgress: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ownerName () {
      return `${this.item.owner.name} ${this.item.owner.lastName || ''}`
    },
    canShowEdit () {
      return Boolean(this.userManagesContent(this.item.workspace ? this.item.workspace.id : this.item.workspaceId) && this.menuOptions.length)
    }
  },
  watch: {
    'item.id' () {
      this.updateComponent('LearningCardImageInfo')
    },
    '$route.name' () {
      if (!this.$route.name.includes('details')) this.preventClick = false
    }
  },
  methods: {
    actionOnClick () {
      !this.preventClick && this.emitClick()
      this.preventClick = true
    },
    emitClick () {
      if (this.item.type === 'content') {
        this.emitShowContentLink()
      } else {
        this.emitSeeMoreAction()
      }
    },
    emitCheckAction () {
      this.checkAction(this.item)
    },
    emitFavoriteAction () {
      this.favoriteAction(this.item)
      this.item.isSaved = !this.item.isSaved
    },
    emitAddOption (action) {
      this.addAction(this.item, action)
    },
    emitSeeMoreAction () {
      this.$emit('interaction')
      this.seeMoreAction(this.item)
    },
    emitShowContentLink () {
      this.$emit('interaction')
      this.showContentLink && this.showContentLink(this.item)
    },
    emitInProgressAction () {
      this.$emit('toggleProgress', this.item.interactionType)
    },
    emitMenuOption (action) {
      this.menuAction(this.item, action)
    },
    doneLoading () {
      this.loadingImage = false
    }
  }
}
</script>
<template>
  <v-card max-height="380" class="learning-card--container" @click.stop="actionOnClick" :key="shouldComponentUpdate['LearningCardImageInfo']">
    <v-img
      :height="item.type === 'list' ? 210 : 120"
      :src="item.coverImageURL || defaultCoverImage"
      class="learning-card--header-image"
      :class="{'learning-card--invisle': loadingImage, 'learning-card--visible': !loadingImage}"
      @load="doneLoading"
    ></v-img>
    <v-container :class="{ 'background-secondary': !item.coverImageURL }" class="learning-card--title title-wrapper">
      <div class="learning-card--header-upper" :class="item.type">
        <div class="learning-card--header-left">
          <v-tooltip bottom v-if="item.isPrivate">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#FFF" medium class="lock-icon" size="24" v-on="on" v-bind="attrs">mdi-lock</v-icon>
            </template>
            <span>{{ item.type === 'content' ? $t('content:private') : $t('list:private') }}</span>
          </v-tooltip>
        </div>
        <div class="learning-hard--header--right">
          <v-tooltip bottom v-if="item.type === 'content'">
              <template v-slot:activator="{on, attrs}">
                <v-btn class="mr-1" small icon color="#FFF" @click.stop="emitFavoriteAction" v-bind="attrs" v-on="on">
                  <v-icon>{{item.isSaved ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
                </v-btn>
              </template>
              <span>{{item.isSaved ? $t('global:undo.save.list') : $t('global:save.list')}}</span>
            </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#FFF" medium class="learning-card--share-button" size="24" v-on="on" v-bind="attrs" @click.stop="shareAction">mdi-share-variant</v-icon>
            </template>
            <span>{{ item.type === 'content' ? $t('content:share') : $t('list:share') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="learning-card--header-middle" v-if="titlePosition === 'top'">
        <h5 class="learning-card--info title-container h8">
          {{ item.title }}
        </h5>
      </div>
      <div class="learning-card--header-bottom" :class="{'learning-content-card--header-bottom': item.type === 'content'}">
        <template>
          <div v-if="item.contents && item.contents.length" class="learning-card--insights-progress-wrapper">
            <v-progress-linear
              :value="item.contentsProgress"
              class="learning-card--insights-progress"
              height="8"
              rounded
              background-color="#DBD5CE"
              :color="getPrimaryColor"
            ></v-progress-linear>
            <span class="learning-card--insights-progress-percent">{{ `${Math.round(item.contentsProgress)}%` }}</span>
          </div>
          <v-chip-group class="insights" v-if="item.amountAssociations && item.type === 'content'">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-chip
                color="#FFF"
                :text-color="getPrimaryColor"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon left>
                  mdi-stairs-box
                </v-icon>
                {{ item.amountAssociations }}
              </v-chip>
            </template>
            <span>{{$tc(`global:number.${item.type}`, item.amountAssociations, [item.amountAssociations])}}</span>
          </v-tooltip>
        </v-chip-group>
        </template>
      </div>
    </v-container>
    <div class="learning-card--card-bottom" :class="{'learning-card--content-card-bottom': item.type === 'content', 'learning-card--list-card-bottom': item.type === 'list'}">
      <v-card-title class="learning-card--title ml-1 h7" :style="{ color: getPrimaryColor }">
        <v-icon class="mr-2" :color="getPrimaryColor">{{ getTitleIcon }}</v-icon>
        {{ getTitle }}
      </v-card-title>
      <v-card-text class="text-left ml-1 mt-n5 learning-card--level-info subtitle--2">
        <div class="learning-card--level">
          {{ $t(`skill.modal.levels:${item.level}`) }}
        </div>
        <div class="learning-card--divisor">
          •
        </div>
        <div class="learning-card--duration">
          {{ totalDurationByMs(item.duration) }}
        </div>
      </v-card-text>
      <div v-if="titlePosition === 'middle'" class="learning-card--title-info-wrapper">
        <h5 class="learning-card--title-info h8">
          {{ item.title }}
        </h5>
      </div>
      <div class="learning-card--owner-info" :class="{'learning-card-owner-list' : item.type === 'list', 'learning-card-owner-content': item.type === 'content'}">
        <v-avatar class="ml-5" size="24" color="indigo">
          <v-img :src="item.owner.imageUrl || defaultAvatarUrl('profile')"/>
        </v-avatar>
        <span class="subtitle--2 neutral-darkest learning-card--owner-name ml-3">{{ ownerName }}</span>
      </div>
      <v-card-actions @click="e => e.stopPropagation()" class="learning-card-actions">
        <div class="learning-card--wrapper ml-4">
          <div class="main-action">
            <v-btn
              v-if="seeMoreAction"
              small
              outlined
              :color="getPrimaryColor"
              class="ml-n4 see-more-btn btn--small btn transform-unset"
              @click.stop="emitSeeMoreAction"
            >
              {{ $t('global:see.more') }}
            </v-btn>
          </div>
          <div class="sub-actions">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn class="mr-1" small icon :color="item.interactionType === 'in_progress' ? '#16AC99' : getPrimaryColor" @click="emitInProgressAction" v-bind="attrs" v-on="on">
                  <v-icon v-if="item.interactionType === 'in_progress'">mdi-play-circle</v-icon>
                  <v-icon v-else>mdi-play-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ item.interactionType === 'in_progress' ? $t(`content.toggle.progress.tooltip:undo`) : $t(`content.toggle.progress.tooltip:set.conclusion`) }}</span>
            </v-tooltip>
            <v-tooltip top v-if="checkAction">
              <template v-slot:activator="{on, attrs}">
                <v-btn class="mr-1" small icon :color="item.interactionType === 'conclusion' ? '#16AC99' : getPrimaryColor" @click="emitCheckAction" v-bind="attrs" v-on="on">
                  <v-icon v-if="item.interactionType === 'conclusion'">mdi-check-circle</v-icon>
                  <v-icon v-else>mdi-check-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ item.interactionType === 'conclusion' ? $t(`content.toggle.conclusion.tooltip:undo`) : $t(`${item.type}.toggle.conclusion.tooltip:set.conclusion`) }}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.type === 'list'">
              <template v-slot:activator="{on, attrs}">
                <v-btn class="mr-1" small icon :color="item.isSaved ? '#16AC99' : getPrimaryColor" @click="emitFavoriteAction" v-bind="attrs" v-on="on">
                  <v-icon>{{item.isSaved ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
                </v-btn>
              </template>
              <span>{{item.isSaved ? $t('global:undo.save.list') : $t('global:save.list')}}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <span v-on="on" v-bind="attrs" v-if="addActionOptions.length">
                  <v-menu bottom left absolute transition="slide-x-transition" min-width="200">
                    <template v-slot:activator="menuProps">
                      <v-btn small icon :color="getPrimaryColor" class="mr-2" v-on="menuProps.on" v-bind="menuProps.attrs"  v-for="(action, i) in addActionOptions" :key="i" @click="emitAddOption(action)">
                        <v-icon>mdi-plus-box-multiple-outline</v-icon>
                      </v-btn>
                    </template>
                    <!-- <v-list>
                      <v-list-item
                        v-for="(action, i) in addActionOptions"
                        :key="i"
                        @click="emitAddOption(action)"
                      >
                        <v-list-item-title>{{ $t(`${item.type}.card.action.options:${action}`) }}</v-list-item-title>
                      </v-list-item>
                    </v-list> -->
                  </v-menu>
                </span>
              </template>
              <span>{{ $t('card.action.add.to.list:button.tooltip') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <span v-bind="attrs" v-on="on" v-if="canShowEdit">
                  <v-menu bottom left absolute transition="slide-x-transition" min-width="200" content-class="learning-card--actions">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon :color="getPrimaryColor" v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(action, i) in menuOptions"
                        :key="i"
                        @click="emitMenuOption(action)"
                      >
                        <v-list-item-title>{{ $t(`${item.type}.card.action.options:${action}`) }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </template>
              <span>{{ $t('card.action.more.options:button.tooltip') }}</span>
            </v-tooltip>
          </div>
          <slot name="modals" :item="item"></slot>
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>
<style lang="scss">
.learning-card--actions {
  .v-list-item {
    text-align: left;
  }
}
.learning-card--container {
  margin: 8px;
  width: 296px;
  height: 380px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  padding-top: 130px;
  .learning-card--header-upper {
    position: absolute;
    display: inline-flex;
    justify-content: space-between;
    width: calc(100% - 12px);
    padding: 3px 18px 8px 8px;
    .learning-card--share-button {
      margin-left: auto;
    }
  }
  .learning-card--wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px;
  }
  .learning-card--header-image {
    position: absolute;
    top: 0;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: $gradient-overlay;
    }
  }
  .learning-card--info {
    color: $neutral-white;
    text-align: left;
    margin-left:8px;
    margin-right:8px;
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    line-height: 1.4;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .learning-card--header-middle {
    height: 100px;
    margin-top: 40px;
  }
  .learning-card--header-bottom {
    display: flex;
    flex-direction: row-reverse;
  }
  .learning-content-card--header-bottom {
    margin-top: 45px;
  }
  .learning-card--title.title-wrapper {
    position: absolute;
    top: 0;
    background-color: transparent;
    z-index: 5;
    > * {
      z-index: 5;
    }
  }
  .learning-card--card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    .content-title {
      margin: 0;
    }
    .v-card__subtitle {
      padding: 0 8px;
    }
    .v-card__actions {
      display: flex;
      padding: 8px;
      justify-content: space-between;
    }
  }
  .learning-card--content-card-bottom {
    height: 265px;
    margin-top: -15px;
  }
  .learning-card--list-card-bottom {
    height: 175px;
    margin-top: 75px;
  }
  @media only screen and (max-width: 768px) {
    max-width: calc(100vw - 46px);
  }
}
.learning-card--level-info {
  color: $neutral-medium;
  height: 40px;
  padding-bottom: 0;
  > div {
    display: inline-block;
  }
  .learning-card--divisor {
    width: 5%;
    margin: 0 3px;
  }
}
.learning-card--title {
  width: 100%;
  color: $primary-medium;
  font-family: $lato;
  font-weight: bold;
  font-size: $font-size-normal;
  text-align: left;
  .v-icon {
    color: $primary-medium
  }
}
.learning-card--title-info-wrapper {
  padding-left:20px;
  padding-right:20px;
  .learning-card--owner-info {
    padding-top: 25px;
  }
  .learning-card-owner-list {
    padding-top: 95px;
  }
  .learning-card-owner-content {
    padding-top: 80px;
  }
}
.learning-card--owner-name {
    align-self: center;
    white-space: nowrap;
    display: inline-block;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }
.learning-card--title-info {
  text-align: left;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
  height: 70px;
}
.insights--content {
  position: absolute;
  right: 0;
  top: 20%;
}
.insights--list {
  position: absolute;
  right: 0;
  top: 38%;
}
.learning-card--insights-progress-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-top: 20px;
}
.learning-card--insights-progress {
  margin-right: 10px;
  margin-bottom: 7px;
}
.learning-card--insights-progress-percent {
  color: #fff;
  font-size: 14px;
}
.content-card--wrapper {
  justify-content: flex-end;
  padding-left: 20px;
  .see-more-btn {
    margin-right: auto;
  }
}
.learning-card--header-top {
  padding: 0 50px;
  height: 50px;
  .content-title {
    width: 100%;
    color: $neutral-white;
    font-family: $lato;
    font-weight: bold;
    font-size: $font-size-normal;
    text-align: left;
  }
}
.learning-card--invisle {
  opacity: 0;
  transition: opacity 1.2s
}
.learning-card--visible {
  opacity: 1;
  transition: opacity 1.2s
}
</style>
