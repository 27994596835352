const contentLevels = ['interest', 'basic', 'intermediate', 'advanced']

const getContentsProgress = (contents) => {
  const total = contents.length
  if (total === 0) return 100

  return (contents.filter((content) => content.interactionType === 'conclusion')).length * 100 / total
}

const highestSkillLevel = (contents) => {
  if (!contents || !contents.length) return 'no.level'
  let highLevel = 'interest'
  for (const content of contents) {
    if (content.contentLevel === 'advanced') {
      highLevel = content.contentLevel
      break
    } else if (contentLevels.indexOf(content.contentLevel) > contentLevels.indexOf(highLevel)) {
      highLevel = content.contentLevel
    }
  }
  return highLevel
}

export default {
  methods: {
    parseLearningPayload (data, type) {
      switch (type) {
        case 'content':
          return {
            ...data,
            type,
            amountAssociations: data.skills.length
          }
        case 'list':
          return {
            ...data,
            type,
            level: highestSkillLevel(data.contents),
            amountAssociations: data.contents.length,
            contentsProgress: getContentsProgress(data.contents)
          }
      }
    }
  }
}
