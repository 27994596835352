<script>
import { required } from 'vuelidate/lib/validators'
import learningCardMixin from '@/mixins/learningCard'
import LearningCardImageInfo from '@/components/general/LearningCardImageInfo'
import ShareModal from '@/components/general/ShareModal'
import ImageCardType from '@/components/general/ImageCardType'
import ModalContentDetails from '@/components/contents/ModalContentDetails'

export default {
  name: 'LearningCardContent',
  mixins: [learningCardMixin],
  props: {
    content: {
      type: Object,
      required
    },
    originWindow: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menuOptions: ['edit', 'delete'],
      addActionOptions: ['addToList'],
      showConfirmToggleConclusion: false,
      showRemovalModal: false,
      shareModal: false,
      showModal: false,
      contentIdProp: {}
    }
  },
  components: {
    ImageCardType,
    ShareModal,
    LearningCardImageInfo,
    ConfirmationModal: () => import('@/components/general/ConfirmationModal'),
    ModalContentDetails
  },
  methods: {
    showShareModal () {
      this.shareModal = true
    },
    closeShareModal () {
      this.shareModal = false
    },
    seeMoreAction (item) {
      const params = { contentId: item.id }
      if (this.$route.name !== 'individual.discovery.index.search') {
        this.$router.push({ name: `${this.$route.name}.content.details`, params: { contentId: item.id } })
      } else {
        this.showModal = true
        this.contentIdProp = params
      }
    },
    addAction (item) {
      const params = { contentId: item.id }
      if (this.$route.name !== 'individual.discovery.index.search' && this.$route.name !== 'business.discovery.index') {
        this.$router.push({ name: `${this.$route.name}.association`, params })
      } else {
        this.$emit('showModalAddListContent', params)
      }
    },
    getDefaultImageByContentType (item) {
      return `/assets/images/background-${item.contentType.alias}.png`
    },
    toggleConclusionModal () {
      this.showConfirmToggleConclusion = !this.showConfirmToggleConclusion
    },
    applyToggleContentConclusion (item) {
      this.$store.dispatch('attemptSetContentInteraction', { contentId: item.id, interaction: 'conclusion' })
        .then(({ data }) => {
          this.content.interactionType = data.currentStatus
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t(`${item.type}.conclusion.toggle:success.${data.currentStatus === 'conclusion' ? 'done' : 'undo'}`)
          })
        })
        .finally(() => {
          this.$store.dispatch('attemptGetMyselfContents')
          this.showConfirmToggleConclusion = false
        })
    },
    removeItem (item) {
      this.$store.dispatch('attemptRemoveContent', item.id)
        .then(({ data }) => {
          if (data) {
            this.$emit('onRemove', this.content.id)
            this.$store.dispatch('attemptGetWorkspaceLists', { filter: this.$store.getters.getCurrentListFilters, workspaceId: this.content.workspace.id })
          }
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('content:toast.delete.error')
          })
        })
        .finally(() => {
          this.showRemovalModal = null
        })
    },
    showContentLink (item) {
      window.open(item.link, '_blank')
      this.$store.dispatch('attemptSetContentInteraction', { contentId: item.id, interaction: 'access' })
        .finally(() => {
        })
    },
    startProgress () {
      this.$store.dispatch('attemptSetContentInteraction', { contentId: this.content.id, interaction: 'in_progress' })
        .then(({ data }) => {
          this.content.interactionType = data.currentStatus
        })
        .finally(() => {
        })
    },
    checkAction (item) {
      this.$store.dispatch('attemptToggleConclusion', item.id)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t(`${item.type}.conclusion.toggle:success.${item.interactionType === 'conclusion' ? 'done' : 'undo'}`)
          })
        })
        .finally(() => {
        })
    },
    executeAction (item, action) {
      const params = { [`${item.type}Id`]: item.id }
      switch (action) {
        case 'edit':
          this.$router.push({ name: `${this.$route.name}.content.manage`, params })
          break

        case 'delete':
          this.showRemovalModal = item
          break
      }
    },
    favoriteAction (item) {
      this.content.isSaved = !this.content.isSaved
      this.$store.dispatch('attemptToggleFavoriteContent', item.id)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t(`user.favorite.content.toggle:${item.isSaved ? 'favorited' : 'unfavorited'}`),
            description: this.$t(`user.favorite.content.toggle:${item.isSaved ? 'favorited' : 'unfavorited'}.description`)
          })
        })
    },
    shareDetails () {
      return {
        link: this.getBaseUrl() + `/my/discovery/content/details/${this.content.id}`
      }
    }
  }
}
</script>
<template>
  <div class="learning-card-content--wrapper">
    <learning-card-image-info :item="parseLearningPayload(content, 'content')" :menuAction="executeAction"
      :seeMoreAction="seeMoreAction" :addAction="addAction" :getTitleIcon="iconsMap[content.contentType.alias]"
      :getTitle="$t(`content.type:${content.contentType.alias}`)" :menuOptions="menuOptions"
      :addActionOptions="addActionOptions" :showContentLink="showContentLink"
      :defaultCoverImage="getDefaultImageByContentType(content)" :coverImageHeight="180"
      :checkAction="applyToggleContentConclusion" :favoriteAction="favoriteAction" :shareAction="showShareModal"
      @interaction="$emit('interaction')" @toggleProgress="startProgress">
      <template v-slot:modals="{ item }">
        <share-modal v-model="shareModal" :details="shareDetails()" @close="closeShareModal">
          <image-card-type :card="content" :defaultImage="getDefaultImageByContentType(content)" />
        </share-modal>
        <confirmation-modal :show="showConfirmToggleConclusion"
          :title="item.interactionType === 'conclusion' ? $t('content.card.modal.conclusion:undo.toggle.title') : $t('content.card.modal.conclusion:toggle.title')"
          :description="item.interactionType === 'conclusion' ? $t('content.card.modal.conclusion:undo.toggle.description') : $t('content.card.modal.conclusion:toggle.description')"
          :confirmText="$t('global:confirm')" @confirm="applyToggleContentConclusion" :cancelText="$t('global:cancel')"
          @cancel="toggleConclusionModal"></confirmation-modal>
        <confirmation-modal :show="!!showRemovalModal" :title="$t('content.remove:title')"
          :description="$t('content.remove:info')" :confirmText="$t('global:delete')" :btnConfirmText="false"
          @confirm="removeItem(showRemovalModal)" :cancelText="$t('global:cancel')" @cancel="showRemovalModal = null" />
      </template>
    </learning-card-image-info>
    <modal-content-details v-if="showModal" :contentIdProp="contentIdProp" @close="showModal = false" />
  </div>
</template>
<style lang="scss">
.learning-card-content--wrapper{
margin-bottom: 40px;
}

</style>
